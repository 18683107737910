import React from "react"
import kebabCase from "lodash/kebabCase"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/Layout"

const title = "Toutes les années"

const YearsPage = ({
  data: {
    allMdx: { group },
  },
}) => (
  <Layout>
    <Helmet title={title} />
    <h1>{title}</h1>
    <ul>
      {group.map(year => (
        <li key={year.fieldValue}>
          <Link to={`/years/${kebabCase(year.fieldValue)}`}>
            {year.fieldValue} ({year.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
)

export default YearsPage
export const pageQuery = graphql`
  query {
    allMdx(limit: 2000) {
      group(field: fields___year) {
        fieldValue
        totalCount
      }
    }
  }
`
